//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;700&display=swap')

@import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,400;0,700;1,300&display=swap');
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~leaflet/dist/leaflet.css";
body {
  position: relative;
  background-image: url(./bg.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center; }

.leaflet-marker-icon {
  z-index: 1000 !important; }

.leaflet-container, .leaflet-popup-content {
  font-family: 'Aleo', serif; }

ul.mg li {
  margin-bottom: .8em; }

#navbar-site {
  box-shadow: 0 .1em .45em rgba(0, 0, 0,.3); }

$primary: #f8d300;
$border-radius: 13px;
$body-bg: #7c1d1d;
$body-color: #ffffff;
$input-color: #000000;
// $font-family-base: 'Noto Sans JP', sans-serif
$font-family-base: 'Aleo', serif;
$font-size-base: 1.1rem;
$enable-shadows: true;

$line-height-base: 1.6;
$line-height-sm: 1.3;
@import "../node_modules/bootstrap/scss/bootstrap";
